import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from 'providers/auth.service';
import { CallService } from 'providers/calls.service';
import { ToasterService } from 'providers/toaster.service';
import { DomSanitizer } from '@angular/platform-browser';
import SiteConfig from '../../assets/site-config.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public score;
  public percent;
  public percentCurrentYear;
  public scoreCurrentMonth;
  public scoreCurrentYear;
  public lands:Array<any>;
  public casa;
  public ano;
  public anos;
  public boxLeft;
  public boxCenter;
  public boxRight;
  public lang;
  public lineChartLabels:Array<any>;
  public currentMonth;
  public city:string;
  public showScores:boolean;
  public showPercentScores:boolean;

  public months = [
      'HOME.Enero',
      'HOME.Febrero',
      'HOME.Marzo',
      'HOME.Abril',
      'HOME.Mayo',
      'HOME.Junio',
      'HOME.Julio',
      'HOME.Agosto',
      'HOME.Septiembre',
      'HOME.Octubre',
      'HOME.Noviembre',
      'HOME.Diciembre'
  ]

  constructor(private translate: TranslateService,
    private httpClient: HttpClient,
    private auth:AuthService,
    private toastService: ToasterService ,
    private router: Router,
    private callService:CallService,
    private sanitizer: DomSanitizer
    ) {
      this.city = SiteConfig.cityName;
      this.showScores = SiteConfig.showScores;
      this.showPercentScores = SiteConfig.showPercentScores;

      const hoy = new Date();
      this.ano = hoy.getFullYear();

      this.anos = [];
      for (let a = hoy.getFullYear(); a > (hoy.getFullYear() - 5); a--) {
          this.anos.push(a);
      }
      if (sessionStorage.getItem('id_token') == null) {
          this.logout();
      } else {

      const user =  this.auth.getUser();

      if (user.lands.length === 0) {
          this.auth.signOutNoRedirect();

          let m = this.translate.instant('ERRORES.noLands');
          this.toastService.Error(m);
          this.router.navigate(['/login']);
          return;
      }

      this.lands = new Array();

      this.casa = user.lands[0].id;
      for (let c = 0; c < user.lands.length; c++) {
          this.lands[c] = {
              id: user.lands[c].id,
              name: user.lands[c].address
          };
      }
      this.lang = this.translate.currentLang;

      this.httpClient.get(`/assets/data/${this.lang}/box-left.html`, { responseType: 'text' }).subscribe(
        data => {
          this.boxLeft = this.sanitizer.bypassSecurityTrustHtml(data);
        }
      );
      this.httpClient.get(`/assets/data/${this.lang}/box-center.html`, { responseType: 'text' }).subscribe(
        data => {
          this.boxCenter = this.sanitizer.bypassSecurityTrustHtml(data)
        }
      );
      this.httpClient.get(`/assets/data/${this.lang}/box-right.html`, { responseType: 'text' }).subscribe(
        data => {
           this.boxRight = this.sanitizer.bypassSecurityTrustHtml(data)
        }
      );
        
      this.getData();
    }

    let d = new Date();
    let n = d.getMonth();

    translate.get(this.months).subscribe(translations => {

      this.currentMonth = translations[ this.months[n] ];

      this.lineChartLabels= [
        translations['HOME.Enero'],
        translations['HOME.Febrero'],
        translations['HOME.Marzo'],
        translations['HOME.Abril'],
        translations['HOME.Mayo'],
        translations['HOME.Junio'],
        translations['HOME.Julio'],
        translations['HOME.Agosto'],
        translations['HOME.Septiembre'],
        translations['HOME.Octubre'],
        translations['HOME.Noviembre'],
        translations['HOME.Diciembre'],
      ];
    })

  };


  ngOnInit() {}

  public lineChartData:Array<any> = [
    {data: new Array(12)}
  ];

  

  public lineChartOptions:any = {
    responsive: true
  };

  public lineChartLegend:boolean = false;
  public lineChartType:string = 'line';

  public lineChartColors:Array<any> = [
    { 
      backgroundColor: 'rgba(88, 159, 205,0.2)',
      borderColor: 'rgba(88, 159, 205,1)',
      pointBackgroundColor: 'rgba(88, 159, 205,1)',
    }
  ];

  getYear() {
    let d = new Date();
    return d.getFullYear();
  }

  getData() {
    this.callService.getAuth(this.casa + '/resume/' + this.ano)
      .subscribe(
        response => {
            const resultado = response.body;
            if (resultado.error) {
                return false;
            }
            this.score = resultado.content.resume.score;
            this.percent = resultado.content.resume.percent;
            this.percentCurrentYear = resultado.content.resume.percentCurrentYear;
            this.scoreCurrentMonth = resultado.content.resume.scoreCurrentMonth;
            this.scoreCurrentYear = resultado.content.resume.scoreCurrentYear;

            const _lineChartData:Array<any> = new Array(1);
            _lineChartData[0] = {data: new Array(12)};

            let max_entrada = 0;
            for (let c = 0; c < 12; c++) {
                _lineChartData[0].data[c] = resultado.content.monthly[c + 1];
                if (resultado.content.monthly[c + 1] > max_entrada) {
                    max_entrada = resultado.content.monthly[c + 1];
                }
            }
            this.lineChartData = _lineChartData;
        },
        error => {
            this.logout();
        }
      );
  }

  logout() {
    this.auth.signOut();
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'providers/auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  //need to put in a config file
  public showFaq = true;
    
  constructor(private auth:AuthService) { }

  ngOnInit() {
  }
  logout() {
    this.auth.signOut();
  }

}

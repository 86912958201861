import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CallService } from 'providers/calls.service';
import { ToasterService } from 'providers/toaster.service';

@Component({
  selector: 'app-recordatorio',
  templateUrl: './recordatorio.component.html',
  styleUrls: ['./recordatorio.component.css']
})
export class RecordatorioComponent implements OnInit {
  public username:string;

  constructor(
      private translate: TranslateService,
      private toastService: ToasterService ,
      private router: Router,
      private callService:CallService
  ) { }

  ngOnInit() {
  }

  recordar() {
      let body = { "user": this.username };
      this.callService.postAuth('remember', body)
      .subscribe(
        response => {
            var resultado = response.body;
            if (resultado.error) {
                let m = this.translate.instant('RECORDATORIO.ERROR');
                this.toastService.Error(m);
            } else {
              let m = this.translate.instant('RECORDATORIO.ENVIADO');
              this.toastService.Success(m);
              this.router.navigate(['/login']);
            }
        },
        error => {
            let m = this.translate.instant('RECORDATORIO.ERROR');
            this.toastService.Error(m);
        }
      );
  }
}

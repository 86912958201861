import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import SiteConfig from '../assets/site-config.json';

@Injectable()
export class CallService {

    private url:string;

    constructor( private httpClient: HttpClient, private auth: AuthService){
        this.url = SiteConfig.url
    }

    private completeUrl(resource:string) : string
    {
       return encodeURI(this.url + resource);
    }

    private authHeaders() 
    {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.auth.getAccessToken()
        });
        return {
            observe: 'response' as 'body',
            headers: headers
        }
    }

    private simpleHeaders() 
    {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        headers.append('Content-Type', 'application/json');
        return {
            observe: 'response' as 'body',
            headers: headers
        }
    }


    getAuth(resource:string) : Observable<any>{
        let url = this.completeUrl(resource);
        let options = this.authHeaders();
        return this.httpClient.get(url, options);
    }

    postAuth(resource:string, body:any) : Observable<any>{
        let content = { content : body};
        let url = this.completeUrl(resource);
        let options = this.authHeaders();
        return this.httpClient.post(url, content, options);
    }
    
    post(resource:string, body:any) : Observable<any>{
        let content = { content : body};
        let url = this.completeUrl(resource);
        let options = this.simpleHeaders();
        return this.httpClient.post(url, content, options);
    }



}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import SiteConfig from '../../assets/site-config.json';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  public faq;
  public lang;
  public city:string;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private httpClient: HttpClient) {
    this.city = SiteConfig.cityName  
    this.lang = this.translate.currentLang;
      this.httpClient.get(`/assets/data/${this.lang}/faq.html`, {responseType: 'text'})
      .subscribe(
         body => {
          this.faq = body;
        }
      );
  }

  ngOnInit() {
  }

  logout() {
      sessionStorage.removeItem('id_token');
      sessionStorage.removeItem('user');
      this.router.navigate(['/login']);
  }
}

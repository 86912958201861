import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { CallService } from 'providers/calls.service';
import { AuthService } from 'providers/auth.service';
import { PagerService } from 'providers/paginator.service';
import SiteConfig from '../../assets/site-config.json';

@Component({
  selector: 'app-entradas',
  templateUrl: './entradas.component.html',
  styleUrls: ['./entradas.component.css']
})
export class EntradasComponent implements OnInit {
  lands;
  ano;
  anos;
  casa;
  entradas
  isDataAvailable
  
  pager: any = {};
  totalItems = 0;

  public showScores:boolean;
  public city:string;

  constructor(private translate: TranslateService,
    private callService:CallService,
    private auth:AuthService,
    private pagerService:PagerService
    ) {
      this.city = SiteConfig.cityName;
      this.showScores = SiteConfig.showScores;
      this.isDataAvailable = false;
      var hoy = new Date();
      this.ano = hoy.getFullYear();
      this.anos = [];
      for (var a = hoy.getFullYear();a > (hoy.getFullYear()-5);a--) {
          this.anos.push(a);
      }
      if (sessionStorage.getItem('id_token') == null) {
          this.logout();
      } else {

      let user = this.auth.getUser();
      this.lands = new Array(user.lands.length);
      this.casa = user.lands[0].id;
      for(var c=0;c< user.lands.length;c++) {
          this.lands[c] = {
              "id": user.lands[c].id,
              "name": user.lands[c].address
          };
      }
      this.getData(1);
      this.getChartData();
    }
  }

  ///
  public pieChartLabels:string[] = [];
  public pieChartData:number[] = [];
  public pieChartType:string = 'pie';
  public pieColors: any[] = [{ backgroundColor: ['#A0DBA5','#F0BA55','#D28FA1','#DB6D40','#82C7CE','#6D464C','#D8CCCC','#FD6872','#616997','#30A8B8','#FCE1E0','#7AB24F'] }];
 
  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalItems, page);
  }

  ngOnInit() {
  }

  hasData()
  {   
    return this.entradas !== undefined;
  }

  getData(page: number) {

        
      this.callService.getAuth(this.casa+'/detail/'+this.ano+'/'+page)
      .subscribe(
        response => {
            var resultado = response.body;
            if (resultado.error == true){
                return false;
            }
            this.entradas = resultado.content.data;
            this.totalItems = resultado.content.total_rows;
            this.setPage(page);
        },
        error => {
            this.auth.signOut();
        }
      );
  }

  getChartData() {
    this.isDataAvailable = false;

    this.callService.getAuth(this.casa+'/entries/'+this.ano)
    .subscribe(
      response => {
          var resultado = response.body;
          if (resultado.error == true){
              return false;
          }

          // en esta version los labels da error -> https://stackoverflow.com/questions/43204025/angular2-update-ng2-charts-with-labels
          let labels:string[] = [];
          let data:number[] = [];
          let label_res = resultado.content.labels;
          let data_res = resultado.content.data;
          for (let index = 0; index < label_res.length; index++) {
            labels.push(String(label_res[index]))
            data.push(Math.round(data_res[index] * 100) / 100);
          }
          this.pieChartData = data;
          this.pieChartLabels = labels;
          this.isDataAvailable = true;
      },
      error => {
          this.logout();
      }
    );
}

  logout() {
    this.auth.signOut();
  }
}

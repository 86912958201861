import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public date:Date = new Date();
  public version:string = environment.VERSION;
  public footer;
  public lang;

  constructor(
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private httpClient: HttpClient    
  ) {
    this.lang = this.translate.currentLang;

    this.httpClient.get(`/assets/data/${this.lang}/footer.html`, { responseType: 'text' }).subscribe(
      data => {
         this.footer = this.sanitizer.bypassSecurityTrustHtml(data)
      }
    );

   }

  ngOnInit() {
  }

}

import { Component, OnInit,ElementRef } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import { CallService } from 'providers/calls.service';
import { AuthService } from 'providers/auth.service';
import { ToasterService } from 'providers/toaster.service';
import SiteConfig from '../../assets/site-config.json';

@Component({
  selector: 'app-contrasena',
  templateUrl: './contrasena.component.html',
  styleUrls: ['./contrasena.component.css']
})

export class ContrasenaComponent implements OnInit {
    contrasena
    contrasena2
    oldContrasena
    mensaje_error

    public city:string;
  
  constructor(private elementRef:ElementRef,
    private translate: TranslateService,
    private router: Router,
    private callService:CallService,
    private auth:AuthService,
    private toastService:ToasterService) {
        this.city = SiteConfig.cityName;
    };

  ngOnInit() {
      this.contrasena = "";
      this.contrasena2 = "";
      this.oldContrasena = "";
  }

  cambiarPass() {
  
      this.mensaje_error = "";
      if (this.oldContrasena == "") {
        // this.mensaje_error = this.translate.instant('CONTRASENA.ERROR-OBLIGATORIO');
        let m = this.translate.instant('CONTRASENA.ERROR-OBLIGATORIO');
        this.toastService.Error(m);
              return false;
      }
      if (this.contrasena == "" || this.contrasena2 == "") {
          // this.mensaje_error = this.translate.instant('CONTRASENA.ERROR-OBLIGATORIO');
          let m = this.translate.instant('CONTRASENA.ERROR-OBLIGATORIO');
          this.toastService.Error(m);
          return false;
      }
      if (this.contrasena != this.contrasena2) {
          // this.mensaje_error = this.translate.instant('CONTRASENA.ERROR-IGUALES');
          let m = this.translate.instant('CONTRASENA.ERROR-IGUALES');
          this.toastService.Error(m);
          return false;
      }


      var u = this.auth.getUser();
      let body =  { "username": u.user, "password": this.contrasena, "oldpassword": this.oldContrasena };
      this.callService.postAuth('password', body)
      .subscribe(
        response => {
            var resultado = response.body;
            
            if (resultado.error) {
              let m = this.translate.instant('CONTRASENA.ERROR-ENVIO');
              this.toastService.Error(m);
              // this.mensaje_error = this.translate.instant('CONTRASENA.ERROR-ENVIO');
            } else {
              let m = this.translate.instant('CONTRASENA.success');
              this.toastService.Success(m);
              this.router.navigate(['/home']);
            }
        },
        error => {
            let m = this.translate.instant('LOGIN.ERROR-USUARIO');
            this.toastService.Error(m);
            // this.mensaje_error = this.translate.instant('LOGIN.ERROR-USUARIO');
        }
      );
  }


}

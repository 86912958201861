import { Injectable } from '@angular/core';
import { Component, OnInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CallService } from 'providers/calls.service';
import { AuthService } from 'providers/auth.service';
import { ToasterService } from 'providers/toaster.service';

@Component({
  selector: 'app-home',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

@Injectable()
export class LoginComponent implements OnInit {

  public username:string;
  public password:string;
  public mensaje_error:string;

  constructor(private elementRef:ElementRef,
    private toastService: ToasterService,
    private translate: TranslateService,
    private router: Router,
    private callService:CallService,
    private auth:AuthService) {};

  ngOnInit() { }

  changeLang(lang) {
     sessionStorage.setItem('lang',lang);
     this.translate.use(lang);
  }

  login() {
      let body = { "user": this.username, "password": this.password };
      this.callService.post('login', body)
      .subscribe(
        response => {
            var resultado = response.body;
            if (resultado.error) {
                if (resultado.content == 'USER_NOT_FOUND') {
                  let m = this.translate.instant('LOGIN.ERROR-USUARIO');
                  this.toastService.Error(m);
                    // this.mensaje_error = this.translate.instant('LOGIN.ERROR-USUARIO');
                } else if (resultado.content == "INVALID_PASSWORD") {
                  let m = this.translate.instant('LOGIN.ERROR-PASSWORD');
                  this.toastService.Error(m);
                    // this.mensaje_error = this.translate.instant('LOGIN.ERROR-PASSWORD');
                }
            } else {
                let credentials = {
                    user:JSON.stringify(resultado.content),
                    access_token: response.headers.get('JWT')
                };
                this.auth.signIn(credentials)
            }
        },
        error => {
            console.log("ERROR", error)
            var resultado = error; //error.json();
            let m = this.translate.instant('LOGIN.ERROR-USUARIO');
            this.toastService.Error(m);
            // this.mensaje_error = this.translate.instant('LOGIN.ERROR-USUARIO');
        }
      );
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { RecordatorioComponent } from './recordatorio/recordatorio.component';
import { FaqComponent } from './faq/faq.component';
import { ContrasenaComponent } from './contrasena/contrasena.component';
import { EntradasComponent } from './entradas/entradas.component';
import { ChartsModule } from 'ng2-charts';
import { AuthService } from 'providers/auth.service';
import { CallService } from 'providers/calls.service';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { PagerService } from 'providers/paginator.service';
import { ToasterService } from 'providers/toaster.service';
import { RootComponent } from './root/root.component';

export function createTranslateLoader(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

const appRoutes: Routes = [
  //{ path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', component: RootComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'recordatorio', component: RecordatorioComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contrasena', component: ContrasenaComponent },
  { path: 'entradas', component: EntradasComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    RecordatorioComponent,
    FaqComponent,
    ContrasenaComponent,
    EntradasComponent,
    NavbarComponent,
    FooterComponent,
    SidenavComponent,
    RootComponent
  ],
  imports: [
    ChartsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  providers: [AuthService, CallService, PagerService, ToasterService],
  bootstrap: [AppComponent]
})
export class AppModule { }

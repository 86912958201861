import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { AuthService } from 'providers/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Wastip';
  url:string;

  constructor(
        public router: Router,
        private translate: TranslateService,
        private auth: AuthService
        ) {
        }

  ngOnInit() {
    this.translate.addLangs([ 'ca', 'es' ]);
    this.translate.setDefaultLang('ca');

    let lang = sessionStorage.getItem('lang');

    if (lang) {
      this.translate.use(lang);
    } else {
      this.translate.use('ca');
    }

  }

  isLogged()
  {
    return this.auth.hasAccessToken();
  }

 
}

import { Injectable } from '@angular/core';

@Injectable()
export class PagerService {
    getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);
        // ensure current page isn't out of range

        if (currentPage < 1) { 
            currentPage = 1; 
        } else if (currentPage > totalPages) { 
            currentPage = totalPages; 
        }
        let startPage: number, endPage: number;

        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
            var pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
        } else {

            var pages:Array<number> = new Array<number>();

            if(currentPage >= 7)
            {
                // afegir punts al principi
                // add  1, 2, ...
                pages.push(1,2,0);
            }else{
                // add 1 fins el num 8
                for (let i=1; i<=8; i++) {
                    pages.push(i);
                }
            }

            if(currentPage >= 7 && currentPage <= totalPages-6)
            {
                 pages.push(currentPage-2,currentPage-1, currentPage, currentPage+1,currentPage+2);
            }

            if(currentPage <= totalPages-6)
            {
                // afegir punts nomes al final
                // add ..., totalPages-1, totalPages
                pages.push(0,totalPages-1,totalPages);

            }else{
                // add currenDate fins totalPages
                for (var i=8; i>=1; i--) {
                    pages.push((totalPages+1)-i);
                }
            }


        }
 
        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            pages: pages
        };
    }
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {

    ACCESS_TOKEN = 'id_token';
    USER_ACCESS = 'user';

    constructor(private router: Router) {
    }

    public getAccessToken() : string
    {
        return `Bearer ${sessionStorage.getItem(this.ACCESS_TOKEN)}`;
    }

    public getUser(): any {
        return JSON.parse(sessionStorage.getItem(this.USER_ACCESS));
    }

    public storeOAuthCredentials(credentials: any) {
        sessionStorage.setItem(this.ACCESS_TOKEN, credentials.access_token);
        sessionStorage.setItem(this.USER_ACCESS, credentials.user);
    }

    public hasAccessToken(): boolean {
        return sessionStorage.getItem(this.ACCESS_TOKEN) != null;
    }

    public removeOAuthCredentials() {
        sessionStorage.removeItem(this.ACCESS_TOKEN);
        sessionStorage.removeItem(this.USER_ACCESS);

    }

    // TODO: Cal arregla aquestes crides, no pot ser que estigui així
    public signOutNoRedirect()
    {
        this.removeOAuthCredentials();
    }

    public signOut()
    {
        this.removeOAuthCredentials();
        this.router.navigate(['/login']);
    }

    public signIn(credentials:any)
    {
        this.storeOAuthCredentials(credentials);
        this.router.navigate(['/home']);
    }
}
